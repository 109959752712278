import { createRef, useEffect } from 'react';
import {
    initializeHiddenBodyStyle,
    useHiddenBody as useHiddenBody,
} from 'src/hooks/common/ui/useHiddenBody';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { useCartContext } from 'src/widgets/CartContext';
import { useCartCheckoutContext } from './CartCheckoutContext';
import { CartCheckoutTabs } from './CartCheckoutTabs';

const hiddenBodyStyle = initializeHiddenBodyStyle('activities');

export function Cart(): JSX.Element {
    const sentinelRef = createRef<HTMLDivElement>();

    const { cartItems, appliedPromoCode, resetIgnoredValueCards } = useCartContext();

    useEffect(() => {
        // Reset ignored value cards when the checkout process starts
        resetIgnoredValueCards();
    }, []);

    const { paymentRedirectContext, appliedGiftcards } = useCartCheckoutContext();

    useEffect(() => {
        sentinelRef.current?.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useHiddenBody(hiddenBodyStyle);

    // Dispatch book event if redirected from Vipps)
    useWidgetEventEffect(
        (cartItems, giftCards, promoCode) => ({
            eventType: 'book',
            cartItems: Object.values(cartItems),
            giftCards: giftCards
                ? giftCards.map((giftCard) => ({
                      priceReduction: giftCard.priceReduction,
                      giftcardReference: giftCard.giftcardStatus.id,
                  }))
                : undefined,
            promoCode: promoCode,
        }),
        cartItems,
        appliedGiftcards,
        appliedPromoCode,
        () => !paymentRedirectContext,
    );

    return <CartCheckoutTabs></CartCheckoutTabs>;
}
