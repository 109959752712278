import { Box, Button, CircularProgress, Grid, useTheme } from '@mui/material';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { ButtonDisplay } from 'src/widgets/activities/checkout/cart/views/ButtonDisplay';
import Summary from '../summary/Summary';
import PaymentOptionsPanel from '../timeslots-payment-options-panel/PaymentOptionsPanel';
import { useCartContext } from 'src/widgets/CartContext';
import { useCartCheckoutContext } from 'src/widgets/activities/checkout/cart/CartCheckoutContext';

type IntentViewProps = {
    handleGoPayClicked: () => void;
};

export default function IntentView(props: IntentViewProps) {
    const { handleGoPayClicked } = props;
    const theme = useTheme();
    const customizations = useCustomizations();

    const { isLoadingPaymentPlan: isLoadingIntents } = useCartContext();
    const { activeTab, largeScreen, isMakingReservation } = useCartCheckoutContext();

    return (
        <>
            <Grid
                container
                justifyContent={largeScreen ? 'space-between' : 'center'}
                alignItems={largeScreen ? 'flex-start' : 'center'}
                direction={largeScreen ? 'row' : 'column-reverse'}
            >
                {!largeScreen && !isLoadingIntents && (
                    <Box
                        maxWidth="440px"
                        width="100%"
                        sx={{
                            [theme.breakpoints.down('md')]: {
                                marginBottom: theme.spacing(3),
                            },
                        }}
                    >
                        <Button
                            sx={{ float: 'right', marginTop: theme.spacing(2) }}
                            color="primary"
                            variant={customizations.primaryButtonStyle}
                            disabled={isMakingReservation}
                            onClick={handleGoPayClicked}
                        >
                            <ButtonDisplay isMakingReservation={isMakingReservation} />
                        </Button>
                    </Box>
                )}

                <Box pt={2}>
                    {!isLoadingIntents && <PaymentOptionsPanel />}
                    {isLoadingIntents && <CircularProgress color="primary" />}
                </Box>

                <Summary
                    showShare
                    hideGoPay={!largeScreen}
                    isCheckoutSummary={true}
                    isMakingReservation={isMakingReservation}
                    goPayClicked={handleGoPayClicked}
                    activeTab={activeTab}
                />
            </Grid>
        </>
    );
}
