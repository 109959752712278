import { Box, FormControl, InputLabel, InputLabelProps, Popper, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMemo } from 'react';
import { useLocale } from '@repo/i18n';
import TextFieldStyleUtils from 'src/components/utils/jss/TextFieldStyleUtils';
import { capitalize } from '@repo/common-utils/TextUtils';
import { Iso2Code } from '@repo/types';
import { getCountries, norway } from '@repo/common-utils/countries';

interface props {
    secondaryColor?: boolean;
    country: Iso2Code | undefined | null;
    onChange(country: Iso2Code): void;
}
export default function CountrySelect(props: props): JSX.Element {
    const { secondaryColor, country = norway, onChange } = props;
    const { t, locale } = useLocale();
    const theme = useTheme();

    const countries = getCountries(locale);
    const isoCodes = Object.entries(countries).sort(([_key1, country1], [_key2, country2]) =>
        country1 > country2 ? 1 : country2 > country1 ? -1 : 0,
    ) as [Iso2Code, string][];

    const value: [Iso2Code, string] | undefined = useMemo(
        () => (country ? [country, countries[country]] : undefined),
        [countries, country],
    );

    return (
        <Autocomplete
            id="bilberry-country-select"
            size="small"
            options={isoCodes}
            onChange={(_event: any, newValue: [Iso2Code, string]) => {
                onChange(newValue[0]);
            }}
            autoHighlight
            disableClearable={true}
            PopperComponent={({ key, ...props }: any) => (
                <Popper {...props} key={props.key} disablePortal={true}>
                    {props.children}
                </Popper>
            )}
            getOptionLabel={([isoCode, _name]) => countries[isoCode] ?? countries['no']}
            isOptionEqualToValue={(option: [Iso2Code, string], value: [Iso2Code, string]) =>
                option[0] === value[0]
            }
            renderOption={({ key, ...props }, [isoCode, name]) => (
                <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    key={key}
                    {...(props as any)}
                >
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${isoCode}.png`}
                        srcSet={`https://flagcdn.com/w40/${isoCode}.png 2x`}
                        alt={`${capitalize(t.flag_of)} ${name}`}
                    />
                    {name}
                </Box>
            )}
            renderInput={(params) => (
                <FormControl fullWidth>
                    <InputLabel
                        htmlFor="bilberry-country-select"
                        required={true}
                        sx={{
                            ...TextFieldStyleUtils.positionInputLabelAboveField,
                            color: theme.palette.secondary.main,
                        }}
                    >
                        {capitalize(t.choose_a_country)}
                    </InputLabel>
                    <TextField
                        required={true}
                        id={params.id}
                        disabled={params.disabled}
                        fullWidth={params.fullWidth}
                        InputLabelProps={params.InputLabelProps as Partial<InputLabelProps>}
                        InputProps={params.InputProps}
                        variant="outlined"
                        color={secondaryColor ? 'secondary' : 'primary'}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: undefined,
                        }}
                        name="country"
                        margin="dense"
                        size="small"
                    />
                </FormControl>
            )}
            value={value}
        />
    );
}
