import { useMemo } from 'react';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import { useLocale } from '@repo/i18n';
import {
    getPaymentPlanInfo,
    PaymentPlanInfo,
    PaymentPlanInfoPeriodCard,
} from '@repo/widget-utils/traversals/paymentPlan';
import {
    MembershipIntentMultiResponse,
    MembershipPaymentPlan,
    MembershipUserValueCard,
} from '@repo/types';
import CreditsPayment from './CreditsPayment';
import MembershipCardPayment from './MembershipCardPayment';
import MoneyPaymentPanel from './MoneyPaymentPanel';
import {
    findValueCardValidToDisplayDateText,
    getValidUntilDisplayText,
} from '@repo/widget-utils/display-helper';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { getValueCardDateRange } from '@repo/widget-utils/DateHelpers';
import { sumBy } from 'lodash-es';
import { useCartContext } from 'src/widgets/CartContext';

function getAllPaymentPlans(intents: MembershipIntentMultiResponse | null) {
    let plans: MembershipPaymentPlan[] = [];
    if (intents?.activityReservations) {
        plans = intents.activityReservations.flatMap((ar) =>
            Object.values(ar.paymentPlanByTourId).flatMap((p) => p.tickets),
        );
    }
    if (intents?.timeslotReservations) {
        plans = plans.concat(intents.timeslotReservations.flatMap((tr) => tr.paymentPlan.tickets));
    }
    return plans;
}

function getNewIdsToIgnore(checked: boolean, currentIdsToIgnore: number[], relevantIds: number[]) {
    const newIdsToIgnore = checked
        ? currentIdsToIgnore.concat(relevantIds)
        : currentIdsToIgnore.filter((id) => !relevantIds.includes(id));
    return newIdsToIgnore;
}

function getVariant(
    isCreditsAndCurrencyNeeded = false,
    forceToValue?: 'radio' | 'checkbox',
): 'radio' | 'checkbox' {
    if (forceToValue) return forceToValue;
    if (isCreditsAndCurrencyNeeded) return 'checkbox';

    return 'radio';
}

export default function PaymentOptionsPanel() {
    const { valueCardsForUser } = useMemberContext();
    const { paymentPlanResponse } = useCartContext();

    const paymentPlanInfo = useMemo(
        () => getPaymentPlanInfo(getAllPaymentPlans(paymentPlanResponse), valueCardsForUser),
        [paymentPlanResponse, valueCardsForUser],
    );

    return (
        <Layout flexDirection="column" spacing="small">
            <Layout flexDirection="column" spacing="wide">
                <Layout flexDirection="column" spacing="small">
                    <MembershipPaymentPanels paymentPlanInfo={paymentPlanInfo} />
                </Layout>
                <Layout flexDirection="column" spacing="small">
                    <OtherPaymentMethodsPanel
                        paymentPlanInfo={paymentPlanInfo}
                    ></OtherPaymentMethodsPanel>
                </Layout>
            </Layout>
        </Layout>
    );
}

function MembershipPaymentPanels(props: { paymentPlanInfo: PaymentPlanInfo }) {
    return (
        <>
            {props.paymentPlanInfo.periodCard.map((periodCard) => {
                return (
                    <MembershipPaymentPanel
                        key={`single-${periodCard.valueCardId}`}
                        periodCard={periodCard}
                    ></MembershipPaymentPanel>
                );
            })}
        </>
    );
}

function MembershipPaymentPanel(props: { periodCard: PaymentPlanInfoPeriodCard }) {
    const { t } = useLocale();
    const { periodCard } = props;
    const validToDisplayDateText = findValueCardValidToDisplayDateText(
        periodCard.membershipUserValueCard,
    );
    const validUntilText = getValidUntilDisplayText(validToDisplayDateText, t);

    const { ignoredValueCards, setIgnoredValueCards } = useCartContext();

    function updatePeriodCardIdsToIgnoreAndIntent(periodCard: PaymentPlanInfoPeriodCard) {
        const newValueCardIdsToIgnore = getNewIdsToIgnore(
            periodCard.checked,
            ignoredValueCards.valueCardIdsToIgnore,
            [periodCard.valueCardId],
        );
        setIgnoredValueCards({
            ...ignoredValueCards,
            valueCardIdsToIgnore: newValueCardIdsToIgnore,
        });
    }

    return (
        <MembershipCardPayment
            text={periodCard.name}
            subtext={validUntilText}
            valueCardDescription={periodCard.description}
            variant={getVariant(false, 'checkbox')}
            isChecked={periodCard.checked}
            onClick={() => updatePeriodCardIdsToIgnoreAndIntent(periodCard)}
            membership={getValueCardDateRange(periodCard.validFrom, validToDisplayDateText)}
        />
    );
}

function OtherPaymentMethodsPanel(props: { paymentPlanInfo: PaymentPlanInfo }) {
    const { t } = useLocale();

    const { paymentPlanInfo } = props;
    const { valueCardsForUser } = useMemberContext();
    const { paymentPlanResponse, ignoredValueCards, setIgnoredValueCards } = useCartContext();

    const creditsVariant = useMemo(() => {
        const paymentPlans = getAllPaymentPlans(paymentPlanResponse);
        const creditCost = paymentPlans
            .flatMap((x) => x.valueCardUsages)
            .reduce((pVal, cVal) => pVal + cVal.creditCost, 0);
        const currencyCost = paymentPlans
            .filter((x) => x.currencyCost !== null)
            .reduce((pVal, cVal) => pVal + (cVal.currencyCost?.price ?? 0), 0);

        const isCreditsAndCurrencyNeeded =
            creditCost === 0 ? false : creditCost > 0 && currencyCost > 0;
        return getVariant(isCreditsAndCurrencyNeeded);
    }, [paymentPlanResponse]);

    function updateCreditsIdsToIgnoreAndIntent() {
        const newValueCardProductTypeIdsToIgnore = getNewIdsToIgnore(
            paymentPlanInfo.creditsGroup.checked,
            ignoredValueCards.valueCardProductTypeIdsToIgnore,
            paymentPlanInfo.creditsGroup.valueCardProductTypeIds,
        );
        setIgnoredValueCards({
            ...ignoredValueCards,
            valueCardProductTypeIdsToIgnore: newValueCardProductTypeIdsToIgnore,
        });
    }

    const isCurrencyPaymentDisabled =
        (paymentPlanInfo.currencyCost > 0 && paymentPlanInfo.creditsGroup.checked) ||
        (paymentPlanInfo.currencyCost <= 0 && !paymentPlanInfo.creditsGroup.checked);

    return (
        <>
            {paymentPlanInfo.periodCard && paymentPlanInfo.periodCard.length > 0 && (
                <PanelTitle>{t.choosePaymentOtherMethods}</PanelTitle>
            )}
            <CreditsPaymentPanel
                paymentPlanInfo={paymentPlanInfo}
                valueCardsForUser={valueCardsForUser}
                variant={creditsVariant}
                updateCreditsIdsToIgnoreAndIntent={updateCreditsIdsToIgnoreAndIntent}
            />
            <MoneyPaymentPanel
                variant={creditsVariant}
                disabled={isCurrencyPaymentDisabled}
                isChecked={paymentPlanInfo.currencyCost > 0}
                onClick={() => {
                    if (!isCurrencyPaymentDisabled && paymentPlanInfo.currencyCost <= 0)
                        updateCreditsIdsToIgnoreAndIntent();
                }}
            />
        </>
    );
}

function CreditsPaymentPanel(props: {
    paymentPlanInfo: PaymentPlanInfo;
    valueCardsForUser: MembershipUserValueCard[];
    variant: 'radio' | 'checkbox';
    updateCreditsIdsToIgnoreAndIntent: () => void;
}) {
    const { t } = useLocale();
    const { paymentPlanInfo, valueCardsForUser, variant, updateCreditsIdsToIgnoreAndIntent } =
        props;

    const valueCards = valueCardsForUser.filter((vc) =>
        paymentPlanInfo.creditsGroup.valueCardIds.includes(vc.id),
    );
    const creditsLeft = sumBy(valueCards, (vc) => (vc.balance?.creditsAvailable ?? 0) / 100);

    const isCreditsPaymentDisabled =
        paymentPlanInfo.currencyCost <= 0 && !paymentPlanInfo.creditsGroup.checked;

    return (
        <>
            {paymentPlanInfo.creditsGroup.valueCardIds.length > 0 && (
                <CreditsPayment
                    key={`single-${paymentPlanInfo.creditsGroup.valueCardIds.join(',')}`}
                    creditsIds={paymentPlanInfo.creditsGroup.valueCardIds}
                    text={t.credits}
                    subtext={creditsLeft + ' ' + t.creditsLeft}
                    variant={variant}
                    disabled={isCreditsPaymentDisabled}
                    isChecked={paymentPlanInfo.creditsGroup.checked}
                    onClick={() => {
                        if (
                            !isCreditsPaymentDisabled &&
                            (!paymentPlanInfo.creditsGroup.checked || variant === 'checkbox')
                        ) {
                            updateCreditsIdsToIgnoreAndIntent();
                        }
                    }}
                />
            )}
        </>
    );
}
