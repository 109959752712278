import {
    BilberryGiftcardStatus,
    BilberryPromoCodeStatus,
    CartItem,
    CheckoutInfo,
    MembershipIntentMultiResponse,
} from '@repo/types';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useAtom } from 'ximple';
import { cartAtom } from 'src/state/cart/cartAtom';
import { useMemberContext } from './timeslots/timeslots/MemberContext';
import { useIntentMulti } from '@repo/widget-utils/api/membership-api';
import {
    loadCheckoutInfoState,
    storeCheckoutInfoState,
} from 'src/state/checkout-info/checkout-info.localstorage';
import { createInitialCheckoutInfoData } from '@repo/widget-utils/checkout-info-helper';
import useIntentMultiRequest from 'src/hooks/domain/cart/useIntentMultiRequest';
import useCartItemsWithMembershipAddedOrRemoved from 'src/hooks/domain/cart/useCartItemsWithMembershipAddedOrRemoved';

export type IgnoredValueCards = {
    valueCardIdsToIgnore: number[];
    valueCardProductIdsToIgnore: number[];
    valueCardProductTypeIdsToIgnore: number[];
};

const initialIgnoredValueCards: IgnoredValueCards = {
    valueCardIdsToIgnore: [],
    valueCardProductIdsToIgnore: [],
    valueCardProductTypeIdsToIgnore: [],
};

type CartContextState = {
    cartItems: CartItem[];
    appliedPromoCode: BilberryPromoCodeStatus | null;
    setAppliedPromoCode: (promoCode: BilberryPromoCodeStatus | null) => void;
    giftcards: BilberryGiftcardStatus[];
    setGiftcards: (giftCards: BilberryGiftcardStatus[]) => void;
    checkoutInfo: CheckoutInfo | null;
    setCheckoutInfo: (checkoutInfo: CheckoutInfo | null) => void;
    isCompanyContact: boolean;
    isUsingPaymentPlan: boolean;
    isLoadingPaymentPlan: boolean;
    paymentPlanResponse: MembershipIntentMultiResponse | null;
    ignoredValueCards: IgnoredValueCards;
    setIgnoredValueCards: (ignoredValueCards: IgnoredValueCards) => void;
    resetIgnoredValueCards: () => void;
};

const CartContext = createContext<CartContextState | undefined>(undefined);

export type CartContextProps = PropsWithChildren;
export function CartContextProvider(props: CartContextProps) {
    const { loggedInUser, currentSite } = useMemberContext();
    const [cartItemsFromStorage] = useAtom(cartAtom);
    const [giftcards, setGiftcards] = useState<BilberryGiftcardStatus[]>([]);
    const [appliedPromoCode, setAppliedPromoCode] = useState<BilberryPromoCodeStatus | null>(null);
    const [checkoutInfo, setCheckoutInfo] = useState<CheckoutInfo | null>(null);
    const isCompanyContact = checkoutInfo?.contactType === 'company';

    const [ignoredValueCards, setIgnoredValueCards] =
        useState<IgnoredValueCards>(initialIgnoredValueCards);

    const resetIgnoredValueCards = () => {
        setIgnoredValueCards(initialIgnoredValueCards);
    };

    const { request: currentPaymentPlanRequest, isUsingPaymentPlan } = useIntentMultiRequest(
        isCompanyContact,
        cartItemsFromStorage,
        appliedPromoCode,
        ignoredValueCards,
    );

    const { data: paymentPlanResponse, isLoading: isLoadingPaymentPlan } = useIntentMulti(
        currentPaymentPlanRequest,
        loggedInUser,
        currentSite?.key,
    );

    useEffect(() => {
        const storedCheckoutInfo = loadCheckoutInfoState();
        setCheckoutInfo(
            createInitialCheckoutInfoData(storedCheckoutInfo, cartItemsFromStorage, loggedInUser),
        );
    }, [cartItemsFromStorage, loggedInUser]);

    useEffect(() => {
        if (checkoutInfo) {
            storeCheckoutInfoState(checkoutInfo);
        }
    }, [checkoutInfo]);

    const cartItems = useCartItemsWithMembershipAddedOrRemoved(
        cartItemsFromStorage,
        isUsingPaymentPlan,
        paymentPlanResponse ?? null,
    );

    return (
        <>
            <CartContext.Provider
                value={{
                    cartItems,
                    appliedPromoCode,
                    setAppliedPromoCode,
                    giftcards,
                    setGiftcards,
                    checkoutInfo,
                    setCheckoutInfo,
                    isCompanyContact,
                    isUsingPaymentPlan,
                    isLoadingPaymentPlan,
                    paymentPlanResponse: paymentPlanResponse ?? null,
                    ignoredValueCards,
                    setIgnoredValueCards,
                    resetIgnoredValueCards,
                }}
            >
                {props.children}
            </CartContext.Provider>
        </>
    );
}

export function useCartContext(): CartContextState {
    const context = useContext(CartContext);
    if (context === undefined) {
        throw new Error('useCartContext must be used within a CartContextProvider');
    }
    return context;
}
