import { useLocale } from '@repo/i18n';
import { setMembershipApiToken } from '@repo/widget-utils/api/membership-api';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { createContext, PropsWithChildren, useContext } from 'react';
import { auth0Atom, isAuthenticatedAtom, previousPathAtom } from 'src/state/authentication';
import { clearUserInputData } from 'src/state/localstorage';
import { useAtom } from 'ximple';

type AuthenticationContextState = {
    isAuthenticated: boolean;
    apiToken: string | null;
    login: () => Promise<void>;
    logout: (leavePageOnLogout: boolean) => void;
};

const AuthenticationContext = createContext<AuthenticationContextState | undefined>(undefined);

export type AuthenticationContextProps = PropsWithChildren;
export function AuthenticationContextProvider(props: AuthenticationContextProps) {
    const [authentication] = useAtom(isAuthenticatedAtom);
    const [auth0] = useAtom(auth0Atom);
    const [, setPreviousPath] = useAtom(previousPathAtom);

    const { locale } = useLocale();
    const config = useConfigurations();

    const apiToken = authentication.token ?? null;
    setMembershipApiToken(apiToken);

    const login = async () => {
        if (!auth0) return;
        setPreviousPath(window.location.pathname + window.location.hash);
        await auth0.loginWithRedirect({
            authorizationParams: {
                redirect_uri: config.loginRedirectUrl,
                ui_locales: locale,
            },
        });
    };

    const logout = (leavePageOnLogout: boolean) => {
        clearUserInputData();

        if (!auth0) return;
        setPreviousPath(window.location.pathname + (leavePageOnLogout ? '' : window.location.hash));
        auth0.logout({
            logoutParams: {
                returnTo: config.loginRedirectUrl,
            },
        });
    };

    return (
        <>
            <AuthenticationContext.Provider
                value={{
                    isAuthenticated: authentication.isAuthenticated,
                    apiToken,
                    logout,
                    login,
                }}
            >
                {props.children}
            </AuthenticationContext.Provider>
        </>
    );
}

export function useAuthenticationContext(): AuthenticationContextState {
    const context = useContext(AuthenticationContext);
    if (context === undefined) {
        throw new Error(
            'useAuthenticationContext must be used within a AuthenticationContextProvider',
        );
    }
    return context;
}
