import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Paper,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getCountryFromSettings, getDialcodes } from '@repo/common-utils/countries';
import CheckoutTabs from 'src/components/domain/checkout/CheckoutTabs';
import ContactPersonForm from 'src/components/domain/checkout-info/ContactPersonForm';
import CreateGiftcard from 'src/components/domain/create-giftcard/CreateGiftcard';
import GiftcardRecipientDetails, {
    RecipientDetails,
} from 'src/components/domain/gift-card-recipient-details/GiftcardRecipientDetails';
import GiftTypeSelections, {
    GiftTypeEnum,
} from 'src/components/domain/gift-type-selection/GiftTypeSelections';
import GiftcardMessage from 'src/components/domain/message-gift-card/GiftcardMessage';
import Payment from 'src/components/domain/payment/Payment';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import { initializeHiddenBodyStyle, useHiddenBody } from 'src/hooks/common/ui/useHiddenBody';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { localeAtom, useLocale } from '@repo/i18n';
import { loadPurchase, storePurchase } from 'src/state/payment/purchase.localstorage';
import {
    BilberryCreateGiftcardRequest,
    ContactPerson,
    CompletedGiftcardPaymentInfo,
    GiftcardPaymentInfo,
    PaymentInfo,
    Purchase,
    Translations,
    Iso2Code,
} from '@repo/types';
import { showError } from 'src/utils/widget/error-handling';
import { capitalize } from '@repo/common-utils/TextUtils';
import { BilberryApiError } from '@repo/widget-utils/api/BilberryApiError';
import {
    createInitialCompletedPaymentInfo,
    validateGiftcardInfo,
} from '@repo/widget-utils/checkout-info-helper';
import { tryFindPaymentRedirectContext } from '@repo/widget-utils/payment-redirect-helper';
import { guardIsValidPaymentGateway } from '@repo/widget-utils/reservation-helper';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import GiftcardReceipt from '../receipt/GiftcardReceipt';
import SummaryGiftcardReceipt from '../receipt/Summary/SummaryGiftcardReceipt/SummaryGiftcardReceipt';
import { useBilberrySettings } from '@repo/widget-utils/api/api';
import { tzdate } from '@repo/tzdate';
import { getPromoCodeDiscount } from '@repo/widget-utils/price-helper';
import { promoCodeAtom } from 'src/state/cart/promoCodeAtom';
import { createGiftcard } from '@repo/widget-utils/api/product.api';
import { useCartContext } from 'src/widgets/CartContext';

const hiddenBodyStyle = initializeHiddenBodyStyle('activities');

export function GiftcardCart(): JSX.Element {
    const { t, locale } = useLocale();

    const paymentRedirectContext = tryFindPaymentRedirectContext();
    const initialActiveTab = paymentRedirectContext ? 2 : 0;
    const storedPurchase = loadPurchase();
    const initialCompletedPaymentInfo = createInitialCompletedPaymentInfo(
        storedPurchase,
        paymentRedirectContext,
    );

    const [activeTab, setActiveTab] = useState(initialActiveTab);

    const config = useConfigurations();
    const countryCode = config.defaultCountry || getCountryFromSettings(locale);

    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [isCreatingGiftcard, setIsCreatingGiftcard] = useState<boolean>(false);
    const [contactPerson, setContactPerson] = useState<ContactPerson>({
        firstName: '',
        lastName: '',
        country: countryCode as Iso2Code,
        phone: { dialCode: getDialcodes()[countryCode as Iso2Code], number: '' },
        email: '',
        address: '',
        postCode: '',
        city: '',
        hasAcceptedPrivacy: false,
        hasAcceptedTerms: false,
    });
    const [selectedGiftType, setSelectedGiftType] = useState<GiftTypeEnum | null>(null);

    const [giftcardMessage, setGiftcardMessage] = useState('');
    const [giftcardValue, setGiftcardValue] = useState(0);
    const [showGiftcardValueError, setShowGiftcardValueError] = useState(false);
    const [recipient, setRecipient] = useState<RecipientDetails>({
        firstName: '',
        lastName: '',
        email: '',
    });
    const { appliedPromoCode } = useCartContext();

    const [paymentInfo, setPaymentInfo] = useState<GiftcardPaymentInfo | null>(null);
    const [completedPaymentInfo, setCompletedPaymentInfo] =
        useState<CompletedGiftcardPaymentInfo | null>(initialCompletedPaymentInfo);

    const { handleGoPayClicked, onPaymentCancelled, onPaymentCompleted, clickedNext } = usePayment(
        t,
        giftcardMessage,
        giftcardValue,
        contactPerson,
        recipient,
        setActiveTab,
        paymentInfo,
        setPaymentInfo,
        setCompletedPaymentInfo,
        setIsCreatingGiftcard,
        appliedPromoCode?.coupon_code,
    );

    useHiddenBody(hiddenBodyStyle);

    const { bilberrySettings } = useBilberrySettings();
    const theme = useTheme();
    const [recipientDetailsIsValid, setRecipientDetailsIsValid] = useState(false);
    const [clickedGoContactInfo, setClickedGoContactInfo] = useState(false);

    useWidgetEventEffect(
        (paymentInfo) => ({
            eventType: 'book',
            giftCard: paymentInfo.createGiftcardRequest,
        }),
        paymentInfo,
        paymentRedirectContext,
    );

    const buttonDisplay = (): JSX.Element => {
        if (isCreatingGiftcard) {
            return (
                <Box>
                    <CircularProgress color="primary" />
                    {capitalize(t.next)} - {capitalize(t.payment)}
                </Box>
            );
        } else {
            return (
                <Box>
                    {capitalize(t.next)} - {capitalize(t.payment)}
                </Box>
            );
        }
    };

    const reservedPrice =
        completedPaymentInfo?.paymentInfo?.giftcard.total ?? paymentInfo?.giftcard.total ?? 0;

    let discount = 0;
    if (appliedPromoCode) {
        const giftcardIdIfLimited = appliedPromoCode.product_max_quantity
            .find((p) => p.is_giftcard)
            ?.product_id.toString();

        const promoCodeUsageContext = {
            promoCodeUsages: new Map<string, number>(),
            promoCodeUsagesPerOrder: new Map<string, number>(),
        };

        discount =
            getPromoCodeDiscount(
                appliedPromoCode,
                promoCodeUsageContext,
                giftcardIdIfLimited ?? '0',
                1,
                giftcardValue,
            )?.amount ?? 0;
    }

    useEffect(() => {
        if (completedPaymentInfo?.paymentInfo) {
            promoCodeAtom.update('');
        }
    }, [completedPaymentInfo]);

    const total = reservedPrice > 0 ? reservedPrice : giftcardValue - discount;

    function getGiftcardTab(): JSX.Element {
        return (
            <Grid
                container
                justifyContent={'center'}
                alignItems={largeScreen ? 'flex-start' : 'center'}
                direction={largeScreen ? 'row' : 'column-reverse'}
            >
                <Grid item justifyContent={largeScreen ? 'flex-start' : 'center'} md={12}>
                    <Grid
                        container
                        item
                        justifyContent={'center'}
                        alignItems={largeScreen ? 'flex-start' : 'center'}
                        direction={largeScreen ? 'row' : 'column-reverse'}
                        wrap={largeScreen ? 'nowrap' : 'wrap'}
                    >
                        <GiftcardMessage
                            largeScreen={largeScreen}
                            message={giftcardMessage}
                            onMessageChanged={setGiftcardMessage}
                        />
                        <CreateGiftcard
                            largeScreen={largeScreen}
                            giftcardValue={giftcardValue}
                            totalPrice={total}
                            onGiftcardValueChanged={setGiftcardValue}
                            showError={showGiftcardValueError}
                            expiryDate={tzdate(bilberrySettings?.giftcard_expires_at, false)}
                        />
                    </Grid>
                    <GiftTypeSelections
                        selectedGiftType={selectedGiftType}
                        setSelectedGiftType={(t) => {
                            setSelectedGiftType(t);
                            if (t !== GiftTypeEnum.Email) {
                                setRecipientDetailsIsValid(true);
                            }
                        }}
                    />
                    {selectedGiftType === GiftTypeEnum.Email && (
                        <GiftcardRecipientDetails
                            showErrors={clickedGoContactInfo}
                            recipient={recipient}
                            onRecipientChange={setRecipient}
                            validate={setRecipientDetailsIsValid}
                        />
                    )}
                    <Grid container justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                handleGoContactInfo(
                                    setClickedGoContactInfo,
                                    giftcardValue,
                                    setShowGiftcardValueError,
                                    selectedGiftType,
                                    recipientDetailsIsValid,
                                    setActiveTab,
                                    paymentInfo,
                                )
                            }
                        >
                            {t.next}
                            {' - '}
                            {capitalize(t.contact_information.short)}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    function getContactInfoTab(): JSX.Element {
        return (
            <Grid
                container
                justifyContent={'center'}
                alignItems={largeScreen ? 'flex-start' : 'center'}
                direction={largeScreen ? 'row' : 'column-reverse'}
            >
                <Grid item justifyContent={'flex-start'} md={12}>
                    <Grid
                        container
                        item
                        justifyContent={'center'}
                        alignItems={largeScreen ? 'flex-start' : 'center'}
                        direction={largeScreen ? 'row' : 'column-reverse'}
                        wrap={largeScreen ? 'nowrap' : 'wrap'}
                    >
                        {!largeScreen && (
                            <Grid container justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleGoPayClicked}
                                    disabled={isCreatingGiftcard}
                                >
                                    {buttonDisplay()}
                                </Button>
                            </Grid>
                        )}
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                '& > *': {
                                    marginTop: theme.spacing(1),
                                },
                                backgroundColor: 'white',
                                width: '100%',
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: '440px',
                                },
                                marginTop: theme.spacing(2),
                                marginRight: largeScreen ? theme.spacing(2) : 0,
                            }}
                            variant="outlined"
                        >
                            <Typography color="secondary" variant="h6" mt={0}>
                                {capitalize(t.contact_information.long)}
                            </Typography>

                            <ContactPersonForm
                                contactPerson={contactPerson}
                                showErrors={clickedNext}
                                onChange={(newContactPerson) => setContactPerson(newContactPerson)}
                                showAddressFields={false}
                            />
                        </Paper>
                        <SummaryGiftcardReceipt
                            giftcardTotal={total}
                            giftcardValue={giftcardValue}
                            selectedGiftType={selectedGiftType}
                            showNextButton={largeScreen}
                            goPayClicked={handleGoPayClicked}
                            isCreatingGiftcard={isCreatingGiftcard}
                            expiryDate={tzdate(bilberrySettings?.giftcard_expires_at, false)}
                            promoCodeWritable
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    function getPaymentTab(): JSX.Element {
        return (
            <Grid
                container
                justifyContent={'center'}
                alignItems={largeScreen ? 'flex-start' : 'center'}
                direction={largeScreen ? 'row' : 'column-reverse'}
            >
                {paymentInfo && (
                    <Grid
                        container
                        justifyContent={largeScreen ? 'space-between' : 'center'}
                        alignItems={largeScreen ? 'flex-start' : 'center'}
                        direction={largeScreen ? 'row' : 'column-reverse'}
                    >
                        <Box sx={{ width: largeScreen ? '50%' : '100%' }}>
                            <Payment
                                onPaymentCancelled={onPaymentCancelled}
                                onPaymentCompleted={onPaymentCompleted}
                                paymentInfo={paymentInfo}
                            />
                        </Box>
                        <Box
                            width={largeScreen ? '400px' : '100%'}
                            sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <SummaryGiftcardReceipt
                                giftcardTotal={total}
                                giftcardValue={giftcardValue}
                                selectedGiftType={selectedGiftType}
                                expiryDate={tzdate(paymentInfo.giftcard.giftcard_expires_at, false)}
                            />
                        </Box>
                    </Grid>
                )}
                {completedPaymentInfo && (
                    <Grid
                        container
                        item
                        justifyContent={'center'}
                        alignItems={largeScreen ? 'flex-start' : 'center'}
                        direction={largeScreen ? 'row' : 'column-reverse'}
                        wrap={largeScreen ? 'nowrap' : 'wrap'}
                    >
                        <Box mr={largeScreen ? theme.spacing(2) : 0}>
                            <GiftcardReceipt
                                bookingRef={completedPaymentInfo.referenceId}
                                shouldSendToRecipient={recipient.email !== contactPerson.email}
                                buyerEmail={
                                    completedPaymentInfo.paymentInfo?.createGiftcardRequest.email
                                }
                                receiverEmail={
                                    completedPaymentInfo.paymentInfo?.createGiftcardRequest
                                        .recipient_email
                                }
                            />
                        </Box>
                        {completedPaymentInfo.paymentInfo && (
                            <SummaryGiftcardReceipt
                                giftcardTotal={total}
                                giftcardValue={giftcardValue}
                                selectedGiftType={selectedGiftType}
                                showPrintButton
                                expiryDate={tzdate(
                                    completedPaymentInfo.paymentInfo?.giftcard.giftcard_expires_at,
                                    false,
                                )}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
        );
    }

    const tabset = {
        ordered: false,
        tabs: [
            {
                name: `01 ${capitalize(t.create)}`,
                title: capitalize(t.create_your_gift),
                backButtonText: capitalize(t.back_to_webshop),
                content: getGiftcardTab(),
                disabled: completedPaymentInfo ? true : false,
            },
            {
                name: `02 ${capitalize(t.contact_information.short)}`,
                title: '',
                hideTitle: true,
                backButtonText: capitalize(t.create),
                backButtonOnClick: () => {
                    setActiveTab(0);
                    return false;
                },
                content: getContactInfoTab(),
                disabled: completedPaymentInfo ? true : false,
            },
            {
                name: `03 ${t.payment}`,
                title: completedPaymentInfo
                    ? capitalize(t.thank_you_for_your_purchase)
                    : capitalize(t.payment),
                backButtonText: capitalize(t.contact_information.short),
                backButtonOnClick: () => {
                    setActiveTab(1);
                    return false;
                },
                hideBackButton: completedPaymentInfo ? true : false,
                content: getPaymentTab(),
                disabled: false,
            },
        ],
    };

    return <CheckoutTabs tabset={tabset} currentTab={activeTab} onChange={setActiveTab} />;
}

function handleGoContactInfo(
    setClickedGoContactInfo: React.Dispatch<React.SetStateAction<boolean>>,
    giftcardValue: number,
    setShowGiftcardValueError: React.Dispatch<React.SetStateAction<boolean>>,
    selectedGiftType: GiftTypeEnum | null,
    recipientDetailsIsValid: boolean,
    setActiveTab: React.Dispatch<React.SetStateAction<number>>,
    paymentInfo: GiftcardPaymentInfo | null,
) {
    const { t } = localeAtom.subject.value;
    setClickedGoContactInfo(true);

    // validate giftcard value
    if (giftcardValue <= 0) {
        showError(t.please_fill_in_all_required_information);
        setShowGiftcardValueError(true);
        return;
    } else {
        setShowGiftcardValueError(false);
    }

    // validate selected gift type
    if (selectedGiftType === null) {
        showError(capitalize(t.please_select_gift_type));
        return;
    }

    // validate recipient details
    if (!recipientDetailsIsValid) {
        showError(capitalize(t.please_fill_in_all_required_information));
        return;
    }

    if (paymentInfo) {
        dispatchWidgetEvent({
            eventType: 'checkoutStep',
            checkoutStep: 'Contact Info',
            giftCard: paymentInfo.createGiftcardRequest,
        });
    }

    setActiveTab(1);
}

function usePayment(
    t: Translations,
    giftcardMessage: string,
    giftcardValue: number,
    contactPerson: ContactPerson,
    recipient: RecipientDetails,
    setActiveTab: (arg: number) => void,
    paymentInfo: GiftcardPaymentInfo | null,
    setPaymentInfo: (paymentInfo: GiftcardPaymentInfo | null) => void,
    setCompletedPaymentInfo: (paymentInfo: CompletedGiftcardPaymentInfo | null) => void,
    setIsCreatingGiftcard: (isCreatingGiftcard: boolean) => void,
    promoCode?: string,
) {
    const [clickedNext, setClickedNext] = useState(false);
    const config = useConfigurations();

    async function handleGoPayClicked() {
        setClickedNext(true);
        const isGiftcardInfoValid = validateGiftcardInfo(giftcardValue, contactPerson);
        if (!isGiftcardInfoValid) {
            showError(capitalize(t.please_fill_in_all_required_information));
            return;
        }

        if (recipient.email === '') {
            recipient.email = contactPerson.email;
            recipient.firstName = contactPerson.firstName;
            recipient.lastName = contactPerson.lastName;
        }

        const siteUrl = window.location.toString();

        const createGiftcardRequest: BilberryCreateGiftcardRequest = {
            amount: giftcardValue,
            email: contactPerson.email,
            name: `${contactPerson.firstName} ${contactPerson.lastName}`,
            message: giftcardMessage,
            termsUrl: config.termsUrl,
            url: siteUrl,
            recipient_email: recipient.email,
            recipient_first_name: recipient.firstName,
            recipient_last_name: recipient.lastName,
            coupon_code: promoCode,
        };
        setIsCreatingGiftcard(true);
        dispatchWidgetEvent({
            eventType: 'checkoutStep',
            checkoutStep: 'Payment',
            giftCard: createGiftcardRequest,
        });
        try {
            const createdGiftcard = await createGiftcard(createGiftcardRequest);
            guardIsValidPaymentGateway(createdGiftcard);
            const paymentInfo: GiftcardPaymentInfo = {
                giftcard: createdGiftcard,
                createGiftcardRequest,
            };

            const purchase: Purchase = {
                referenceId: createdGiftcard.id.toString(),
                paymentId: createdGiftcard.payment_id,
                amount: createdGiftcard.total,
                cartItems: [],
                giftcards: [createdGiftcard],
                isAnalyticsNotified: false,
            };
            storePurchase(purchase);

            setPaymentInfo(paymentInfo);
            setActiveTab(2);
        } catch (error) {
            const errorOccuredMessage = capitalize(t.error_occurred_when_creating_giftcard);
            let apiErrorMessages: string[] = [];

            if (error instanceof BilberryApiError) {
                apiErrorMessages = await error.errorMessages();
            }

            showError(`${errorOccuredMessage}. ${apiErrorMessages.join(',')}`);
        }
        setIsCreatingGiftcard(false);

        return;
    }

    function onPaymentCancelled() {
        setPaymentInfo(null);
        setActiveTab(1);
        if (paymentInfo) {
            dispatchWidgetEvent({
                eventType: 'checkoutStep',
                checkoutStep: 'Cancel',
                giftCard: paymentInfo.createGiftcardRequest,
            });
        }
    }

    function onPaymentCompleted(
        paymentInfo: PaymentInfo | GiftcardPaymentInfo,
        paymentGatewayResponse: any,
    ) {
        const knownPaymentInfoType = paymentInfo as GiftcardPaymentInfo;
        const completedPaymentInfo: CompletedGiftcardPaymentInfo = {
            paymentId: knownPaymentInfoType.giftcard.payment_id,
            referenceId: knownPaymentInfoType.giftcard.id.toString(),
            paymentInfo: knownPaymentInfoType,
            paymentGatewayResponse,
        };

        setPaymentInfo(null);
        setCompletedPaymentInfo(completedPaymentInfo);
        setActiveTab(2);

        dispatchWidgetEvent({
            eventType: 'book',
            giftCard: knownPaymentInfoType.createGiftcardRequest,
        });
        storePurchase(null);
    }
    return {
        handleGoPayClicked,
        onPaymentCancelled,
        onPaymentCompleted,
        clickedNext,
    };
}
