import { Box, capitalize, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useMemo, useState, useContext } from 'react';
import { useLocale, getLocaleNumberFormatTwoDecimals } from '@repo/i18n';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import {
    calculateAppliedGiftcards,
    getPriceSummaryFromCartItems,
} from '@repo/widget-utils/price-helper';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { ShareCartButton } from 'src/widgets/activities/share-cart-button/ShareCartButton';
import { SharePanel } from 'src/widgets/activities/share-cart-button/SharePanel';
import SummaryBooking from './SummaryBooking';
import SummaryApplyGiftCardPayments from './SummaryApplyGiftCardPayments';
import SummaryPayment from './SummaryPayment';
import SummaryPromoCode from './SummaryPromoCode';
import SummaryVat from './SummaryVat';
import { showError } from 'src/utils/widget/error-handling';
import { sumBy, uniqBy } from 'lodash-es';
import { CartItem, BilberryGiftcardStatus, AppliedGiftCard } from '@repo/types';
import { useCartContext } from 'src/widgets/CartContext';
import { CheckoutTabType } from 'src/widgets/activities/checkout/cart/views/getTabIndex';

interface IProps {
    goPayClicked?: () => void;
    goPayClickedInvoice?: () => void;
    isMakingReservation?: boolean;
    goToCheckout?: () => void;
    isCheckoutSummary: boolean;
    invertedColors?: boolean;
    heading?: string;
    nextButtonText?: string;
    onCloseModal?: () => void;
    isConfirmationPage?: boolean;
    cartItems?: CartItem[];
    hideGoPay: boolean;
    showShare?: boolean;
    activeTab: CheckoutTabType | null;
}

export default function Summary(props: IProps): JSX.Element {
    const { t, locale } = useLocale();
    const customizations = useCustomizations();
    const theme = useTheme();
    const configurations = useConfigurations();
    const {
        cartItems: cartItemsFromStore,
        giftcards,
        setGiftcards,
        appliedPromoCode,
        setAppliedPromoCode,
    } = useCartContext();
    const [shareDialogVisible, setShareDialogVisible] = useState(false);
    const [shareUrl, setShareUrl] = useState('');

    const { activeTab } = props;

    const cartItems = props.cartItems ?? Object.values(cartItemsFromStore);

    const priceSummary = getPriceSummaryFromCartItems(cartItems, appliedPromoCode);
    const appliedGiftcards = calculateAppliedGiftcards(giftcards, priceSummary.totalPrice);
    const invertedColorsClass = props.invertedColors
        ? {
              backgroundColor: customizations.bookingWidgetColor,
              color: customizations.bookingWidgetColorContrast,
              '& .MuiIconButton-root': {
                  color: customizations.secondaryColorContrast,
              },
              '& .MuiSvgIcon-root': {
                  color: customizations.secondaryColorContrast,
              },
          }
        : {};
    const primaryColorsClass = props.invertedColors
        ? { color: customizations.bookingWidgetPrimaryColor }
        : {};
    const priceReductionGiftCard = sumBy(appliedGiftcards, (x) => x.priceReduction);

    const totalPriceWithPriceReduction = Math.max(
        0,
        priceSummary.totalPrice - priceReductionGiftCard,
    );

    const promoCodeWritable =
        configurations.enablePromoCodes && !(activeTab === null || activeTab === 'payment');

    const isGiftcardEnabled =
        !!configurations.enableGiftcards && activeTab !== null && activeTab !== 'payment';

    const displayGiftcardValue =
        !!configurations.enableGiftcards && appliedGiftcards?.length > 0 && activeTab === 'payment';

    const handleApplyGiftcard = (giftcard: BilberryGiftcardStatus) => {
        const oldGiftcardStatuses = appliedGiftcards.map((x) => x.giftcardStatus);
        const newGiftcardStatuses = uniqBy([...oldGiftcardStatuses, giftcard], (x) => x.id);

        setGiftcards(newGiftcardStatuses);
    };

    const handleRemoveAppliedGiftcard = (appliedGiftcard: AppliedGiftCard) => {
        const newGiftcardStatuses = appliedGiftcards
            .filter((x) => x !== appliedGiftcard)
            .map((x) => x.giftcardStatus);

        setGiftcards(newGiftcardStatuses);
    };

    const currentPromoCodePriceReduction = useMemo(() => {
        const { promoCodeDiscounts } = getPriceSummaryFromCartItems(cartItems, appliedPromoCode);

        return promoCodeDiscounts.reduce((acc, cur) => acc + cur.amount, 0);
    }, [cartItems, appliedPromoCode]);

    return (
        <Grid
            component={Paper}
            mt={theme.spacing(2)}
            p={theme.spacing(3, 3, 2, 3)}
            maxWidth="400px"
            width="100%"
            sx={{
                backgroundColor: theme.palette.grey[50],
                color: theme.palette.primary.main,
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                    maxWidth: '440px',
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                },
                '@media print': {
                    '& .no-print': {
                        display: 'none',
                    },
                },
                ...invertedColorsClass,
            }}
            variant="outlined"
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: 'auto auto',
                    gridTemplateColumns: '1fr 1fr',
                    gap: theme.spacing(1),
                    pb: theme.spacing(2),
                }}
            >
                <Typography
                    color="textSecondary"
                    variant="h4"
                    fontWeight={500}
                    fontSize={28}
                    sx={{ ...invertedColorsClass, ...primaryColorsClass, flexGrow: 1 }}
                >
                    {props.heading ?? t.summary}
                </Typography>
                {props.showShare && (
                    <ShareCartButton
                        hasInvertedColors={props.invertedColors}
                        setShareUrl={setShareUrl}
                        shareDialogVisible={shareDialogVisible}
                        setShareDialogVisible={setShareDialogVisible}
                        sx={{
                            mr: props.invertedColors ? theme.spacing(4) : 0,
                            justifySelf: 'end',
                        }}
                    />
                )}
                {props.showShare && (
                    <SharePanel
                        url={shareUrl}
                        visible={shareDialogVisible}
                        invertedColorsClass={invertedColorsClass}
                        sx={{ gridRow: 2, gridColumn: '1 / 3' }}
                    />
                )}
            </Box>
            <SummaryBooking
                invertedColorsClass={invertedColorsClass}
                cartItems={cartItems}
                isCheckoutSummary={props.isCheckoutSummary}
                isConfirmationPage={props.isConfirmationPage}
            />
            <SummaryVat priceSummary={priceSummary} invertedColorsClass={invertedColorsClass} />
            {configurations.enablePromoCodes && (
                <SummaryPromoCode
                    invertedColorsClass={invertedColorsClass}
                    onPromoCodeApplied={setAppliedPromoCode}
                    appliedPromoCode={appliedPromoCode}
                    currentPriceReduction={currentPromoCodePriceReduction}
                    writeable={promoCodeWritable}
                />
            )}
            {isGiftcardEnabled && (
                <SummaryApplyGiftCardPayments
                    appliedGiftcards={appliedGiftcards}
                    onApplyGiftcard={handleApplyGiftcard}
                    onRemoveAppliedGiftcard={handleRemoveAppliedGiftcard}
                    onError={showError}
                />
            )}
            {displayGiftcardValue && (
                <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        wrap="nowrap"
                        flex="3"
                        flexShrink="1"
                    >
                        <Typography color="secondary" align="right">
                            {capitalize(t.gift_card)}
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        wrap="nowrap"
                        flex="1"
                        flexGrow="1"
                    >
                        <Typography color="secondary" align="right">
                            -{getLocaleNumberFormatTwoDecimals(locale, priceReductionGiftCard)}
                        </Typography>
                    </Grid>
                </Grid>
            )}

            <SummaryPayment
                invertedColorsClass={invertedColorsClass}
                hideGoPay={props.hideGoPay}
                goPayClicked={props.goPayClicked}
                goPayClickedInvoice={props.goPayClickedInvoice}
                isMakingReservation={props.isMakingReservation}
                goToCheckout={props.goToCheckout}
                totalPrice={totalPriceWithPriceReduction}
                onCloseModal={props.onCloseModal}
                isConfirmationPage={props.isConfirmationPage}
                enableGoPay={cartItems.length > 0}
                nextButtonText={props.nextButtonText}
            />
        </Grid>
    );
}
